import React from "react"
import { Link } from "gatsby"

class NavbarMenu extends React.Component {
  render() {
    function toggleMoreInfo() {
      document.querySelector('#more_info').classList.toggle('is-active');
    }
    return (
      < >
        <Link className="navbar-item" to="/">
          Home
        </Link>
        <Link className="navbar-item" to="/documents">
          Documents
        </Link>
        <Link className="navbar-item" to="/tax-information">Tax Information</Link>
        <div id="more_info" className="navbar-item has-dropdown is-hoverable">
          <div className="navbar-link" onClick={toggleMoreInfo}>More Info</div>
          <div className="navbar-dropdown">
            <Link className="navbar-item" to="/board-of-directors">Board of Directors</Link>
            <Link className="navbar-item" to="/district-consultants"> District Consultants </Link>
            <Link className="navbar-item" to="/election-information">Election Information</Link>
            <Link className="navbar-item" to="/budget-information">Budget Information</Link>
            <Link className="navbar-item" to="/useful-links">Useful Links</Link>
            <Link className="navbar-item" to="/calendar">Calendar</Link>
            <Link className="navbar-item" to="/map">Map</Link>
          </div>
        </div>
        <Link className="navbar-item" to="/contact">
          Contact Us
        </Link>
      </ >
    )
  }
}

export default NavbarMenu
