import React from "react"
import { Link } from "gatsby"
import logo from "../img/logo-white.svg"
import NavbarMenu from './NavbarMenu'

class Navbar extends React.Component {
  render() {
    function toggleMenu() {
      document.querySelector(".navbar-burger").classList.toggle("is-active")
      document.querySelector(".navbar-menu").classList.toggle("is-active")
    }
    function toggleMoreInfo() {
      document.querySelector('#more_info').classList.toggle('is-active');
    }
    function toggleMembership() {
      document.querySelector('#membership').classList.toggle('is-active');
    }
    function toggleOperations() {
      document.querySelector('#operations').classList.toggle('is-active');
    }
    return (
      < >
      <nav className="navbar">
        <div className="container" style={{ alignItems: "center" }}>
            <div className="navbar-brand  is-hidden-desktop ">
              <Link to="/" className="">
                <figure>
                  <img
                    src={logo}
                    alt="Montgomery Municipal Utiliy District 42"
                    className="logo"
                  />
                </figure>
              </Link>
              <button
                className="button navbar-burger"
                onClick={toggleMenu}
                aria-label="menu"
                aria-expanded="false"
              >
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </button>
            </div>
          <div className="navbar-menu">
            <div className="navbar-brand is-hidden-touch is-adjusted">
              <Link to="/" className="">
                <figure>
                  <img
                    src={logo}
                    alt="Montgomery Municipal Utiliy District 42"
                    className="logo"
                  />
                </figure>
              </Link>
              <button
                className="button navbar-burger"
                onClick={toggleMenu}
                aria-label="menu"
                aria-expanded="false"
              >
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </button>
            </div>
            <div className="navbar-end">
              <NavbarMenu />
              {/* <div id="membership" className="navbar-item has-dropdown is-hoverable">
                <div className="navbar-link" onClick={toggleMembership}>Membership</div>
                <div className="navbar-dropdown">
                  <Link className="navbar-item" to="/employment">Employment</Link>
                  <Link className="navbar-item" to="/volunteer">Volunteer</Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </nav>
      </ >
    )
  }
}

export default Navbar
